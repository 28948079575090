import { createSlice } from "@reduxjs/toolkit";
import { AppThunkPromise } from "./store";
import { createAsyncActions } from "./util";

export type AzureMapsState = {
  areAzureMapsLoading: boolean;
  areAzureMapsReady: boolean;
};

const loadAzureMapsActions = createAsyncActions<void, void, void>(
  "azureMaps/load"
);

export const loadAzureMaps =
  (): AppThunkPromise<void> => async (dispatch, getState) => {
    const loadScript = async () => {
      dispatch(loadAzureMapsActions.loading());
      const script = document.createElement("script");
      script.src =
        "https://atlas.microsoft.com/sdk/javascript/mapcontrol/3/atlas.min.js";
      const stylesheet = document.createElement("link");
      stylesheet.rel = "stylesheet";
      stylesheet.href =
        "https://atlas.microsoft.com/sdk/javascript/mapcontrol/3/atlas.min.css";
      document.head.appendChild(script);
      document.head.appendChild(stylesheet);

      return new Promise<void>((resolve) => {
        script.onload = () => {
          dispatch(loadAzureMapsActions.success());
          resolve();
        };
      });
    };

    await loadScript();
  };

export const azureMapsSlice = createSlice({
  name: "azureMaps",
  initialState: {
    areAzureMapsLoading: false,
    areAzureMapsReady: false,
  } as AzureMapsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadAzureMapsActions.loading, (s) => {
      s.areAzureMapsLoading = true;
    });
    builder.addCase(loadAzureMapsActions.success, (s) => {
      s.areAzureMapsLoading = false;
      s.areAzureMapsReady = true;
    });
  },
});

export const azureMapsReducer = azureMapsSlice.reducer;
