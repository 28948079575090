import { GlobalStyles, ThemeProvider } from "@greco/components";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Navigate, Route, Routes } from "react-router-dom";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createGlobalStyle } from "styled-components";
import "./App.scss";
import { ProtectedRoute } from "./ProtectedRoute";
import { checkIsLoggedIn, getAccount, login } from "./api/auth";
import { NoAccess } from "./components/NoAccess";
import { Tooltip } from "./components/Tooltip";
import { AppHeader } from "./components/app-header/AppHeader";
import { IHeaderConfig, headerConfig } from "./config/headerConfig";
import { routes } from "./config/routes";
import { setIsLoggedIn } from "./store/auth";
import { loadAzureMaps } from "./store/azureMaps";

import { HistoryRouter } from "redux-first-history/rr6";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { useSelector } from "./store/hooks";
import { AppDispatch, history } from "./store/store";
import { loadTaxonomyActions } from "./store/taxonomy/taxonomy";
import { setUserRoleAndEmail } from "./store/userRole";
import { darkStyledTheme, styledTheme } from "./theme/index";

interface IAppState {
  headerConfig: IHeaderConfig;
  panelIsOpen: boolean;
  user: any;
  error: string;
  loading: boolean;
  search: string;
  appInsights: ApplicationInsights;
}

export const App = () => {
  const [state, setState] = useState<IAppState>({
    headerConfig: headerConfig,
    panelIsOpen: false,
    user: {},
    error: "",
    loading: false,
    search: "",
    appInsights: null,
  });

  const dispatch: AppDispatch = useDispatch();
  const hasRights = useSelector((s) => s.auth.hasRights);

  // on mount
  useEffect(() => {
    const fn = async () => {
      const isLoggedIn = await checkIsLoggedIn();
      const usp = new URLSearchParams(window.location.search);
      const langParam = usp.get("lang");
      if (!isLoggedIn) {
        return login(langParam ? langParam.slice(0, 2) : undefined);
      }
      const account = getAccount();
      const email = account?.idToken?.emails[0];
      if (isLoggedIn) {
        dispatch(setUserRoleAndEmail({ userRole: "external", email: email }));
      } else {
        dispatch(setUserRoleAndEmail({ userRole: null, email: null }));
      }

      dispatch(setIsLoggedIn({ isLoggedIn: true }));
    };
    fn();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      dispatch(loadAzureMaps());
      dispatch(loadTaxonomyActions.trigger());
    })();
  }, [dispatch]);

  return (
    <HistoryRouter history={history}>
      <StyledThemeProvider
        theme={
          (Boolean(state.headerConfig.darkMode)
            ? darkStyledTheme
            : styledTheme) as any
        }
      >
        <ThemeProvider isDarkMode={Boolean(state.headerConfig.darkMode)}>
          {hasRights && (
            <>
              <AppHeader />
              <div className={`app-wrapper`}>
                <Routes>
                  {Object.keys(routes).map((key) => {
                    const route = routes[key];
                    return (
                      <Route
                        key={key}
                        path={route.path}
                        element={
                          <ProtectedRoute>{<route.component />}</ProtectedRoute>
                        }
                      />
                    );
                  })}
                  <Route
                    path="*"
                    element={<Navigate to="/value-monitoring" replace />}
                  />
                </Routes>
                <ToastContainer />
                <GlobalStyles />
                <GlobalStyle />
                <Tooltip />
              </div>
            </>
          )}
          {!hasRights && <NoAccess />}
        </ThemeProvider>
      </StyledThemeProvider>
    </HistoryRouter>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: white;
  }
  input:-internal-autofill-selected{
    background-color: white !important;
    background-image: none !important;
  }

  .Toastify__toast-container {
    padding: 0;

    .Toastify__toast--default {
    }
    .Toastify__toast--info {
    }
    .Toastify__toast--success {
      .Toastify__progress-bar {
        background-color: green;
      }
    }
    .Toastify__toast--warning {
      .Toastify__progress-bar {
        background-color: orange;
      }
    }
    .Toastify__toast--error {
      .Toastify__progress-bar {
        background-color: #c50f1f;
      }
    }
    .Toastify__toast--error {
    }
    .Toastify__toast {
      min-height: unset;
      padding: 0;
      border: 1px solid #ccc;
    }
    .Toastify__close-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 0 10px;
      color: black;
    }
  }
`;

export default App;
