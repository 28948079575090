import { DefaultButton, Icon, Modal, PrimaryButton } from "@fluentui/react";
import atlas, { AuthenticationType } from "azure-maps-control";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { createGlobalStyle } from "styled-components";
import { searchLocationByPoint } from "../../../api/maps";
import { HSpace } from "../../../components/styled";
import countries from "../../../countries.json";
import { useSelector } from "../../../store/hooks";
import { azureMapsSearch } from "../../../utils/maps";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export const MapModal = ({ isOpen, closeModal }: Props) => {
  const areAzureMapsReady = useSelector((s) => s.azureMaps.areAzureMapsReady);
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const { setFieldValue, values } = useFormikContext<any>();
  const [loc, setLoc] = useState({
    address: null,
    lat: null,
    long: null,
  });

  const [searchBox, setSearchBox] = useState("");
  const [searchBox2, setSearchBox2] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [isSearchListReady, setIsSearchListReady] = useState(false);

  const handleSearch = async (query) => {
    if (window.map && isSearchListReady) {
      const results = await azureMapsSearch(query, window.map);
      setSearchResults(results);
    }
  };

  useEffect(() => {
    if (searchBox) {
      handleSearch(searchBox);
    }
  }, [searchBox]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("searchBox")?.blur();
      setIsSearchListReady(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (!areAzureMapsReady) return;
    if (!isOpen) return;

    setTimeout(() => {
      var formvals = values;

      // Ensure longitude and latitude are correctly ordered
      var center =
        formvals && formvals.latitude && formvals.longitude
          ? new atlas.data.Position(
              Number(formvals.longitude),
              Number(formvals.latitude)
            )
          : null;

      window.map = new atlas.Map("azure-map", {
        view: "Auto",

        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY,
        },
        cameraOptions: {
          center: center,
        },
      });
      if (center) {
        window.map.setCamera({ center: center, zoom: 16 });
      } else {
        window.map.setCamera({
          center: new atlas.data.Position(23, 53),
          zoom: 3,
        });
      }
      if (center) {
        var pin = new atlas.HtmlMarker({
          position: center,
          color: "#005aa1",
        });
        window.map.markers.add(pin);
        setSearchBox(formvals.locationName);
        setSearchBox2(center[1] + "," + center[0]);

        // Add a separate text element below the pin
        const textElement = document.createElement("div");
        textElement.innerText = formvals.locationName;
        textElement.style.position = "absolute";
        textElement.style.transform = "translate(-50%, 10px)";
        textElement.style.textAlign = "center";
        textElement.style.width = "300px";
        textElement.style.pointerEvents = "none";
        pin.getElement().appendChild(textElement);
      }

      const onPlacePin = async (e: any) => {
        window.map.markers.clear();
        const pin = new atlas.HtmlMarker({
          position: [e.position[0], e.position[1]],
          color: "#005aa1",
          draggable: true,
        });
        pin._addEventListener(
          "dragend",
          function (e) {
            onPlacePin(e);
          },
          false
        );
        window.map.markers.add(pin);

        const locRes = await searchLocationByPoint(
          e.position[1],
          e.position[0]
        );

        pin.setOptions({
          title:
            locRes[0]?.address?.streetNameAndNumber ||
            locRes[0]?.address?.localName ||
            "",
          secondaryHtmlContent:
            locRes[0]?.address?.streetNameAndNumber ||
            locRes[0]?.address?.localName ||
            "",
        });

        const lat = locRes[0]?.position?.split(",")[0];
        const long = locRes[0]?.position?.split(",")[1];
        setLoc({
          address: locRes[0]?.address,
          lat: lat,
          long: long,
        });

        setSearchBox(locRes[0]?.address.freeformAddress || "");
        setSearchBox2(lat ? lat + "," + long : "");
        setSearchResults([]);

        // Add a separate text element below the pin
        const textElement = document.createElement("div");
        textElement.innerText = locRes[0]?.address.freeformAddress || "";
        textElement.style.position = "absolute";
        textElement.style.transform = "translate(-50%, 10px)";
        textElement.style.textAlign = "center";
        textElement.style.width = "300px";

        textElement.style.pointerEvents = "none";
        pin.getElement().appendChild(textElement);
      };

      window.map.events.add("click", onPlacePin);

      window.map.events.add("click", () => {
        setSearchResults([]);
      });

      function suggestionSelected(result: any) {
        //Remove previously selected suggestions from the map.
        window.map.markers.clear();
        //Show the suggestion as a pushpin and center map over it.
        const pin = new atlas.HtmlMarker({
          position: [result.position[0], result.position[1]],
          color: "#005aa1",
        });
        window.map.markers.add(pin);

        window.map.setCamera({ center: result.position, zoom: 16 }); // Set zoom level to 16

        setLoc({
          address: result.address,
          lat: result.position[1],
          long: result.position[0],
        });

        setSearchBox2(result.position[1] + "," + result.position[0]);
        setSearchBox(result.formattedAddress);

        // Add a separate text element below the pin
        const textElement = document.createElement("div");
        textElement.innerText = result.formattedAddress;
        textElement.style.position = "absolute";
        textElement.style.transform = "translate(-50%, 10px)";
        textElement.style.textAlign = "center";
        textElement.style.width = "300px";
        textElement.style.pointerEvents = "none";
        pin.getElement().appendChild(textElement);
      }
    }, 0);
  }, [areAzureMapsReady, isOpen]);

  if (!isOpen) return null;

  const onKeyPress = async (e: any) => {
    let allow_char = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ",",
      ".",
      "-",
    ];
    if (e.which === 13) {
      var vc = getCoordinate(e.target.value);
      if (vc) {
        var latitude = vc[0];
        var longitude = vc[1];
        var result = await searchLocationByPoint(latitude, longitude);
        if (result && result.length > 0) {
          var res = result[0];
          //Remove previously selected suggestions from the map.
          window.map.markers.clear();
          //Show the suggestion as a pushpin and center map over it.
          var center = new atlas.data.Position(vc[1], vc[0]);
          const pin = new atlas.HtmlMarker({
            position: center,
            color: "#005aa1",
          });
          window.map.markers.add(pin);

          window.map.setCamera({ center: center, zoom: 16 }); // Set zoom level to 16
          setLoc({
            address: res.address,
            lat: latitude,
            long: longitude,
          });

          setSearchBox(res.address.freeformAddress);

          // Add a separate text element below the pin
          const textElement = document.createElement("div");
          textElement.innerText = res.address.freeformAddress;
          textElement.style.position = "absolute";
          textElement.style.transform = "translate(-50%, 10px)";
          textElement.style.textAlign = "center";
          textElement.style.width = "300px";
          textElement.style.pointerEvents = "none";
          pin.getElement().appendChild(textElement);
        }
      }
    } else if (allow_char.indexOf(e.key) === -1) {
      e.preventDefault();
      return false;
    } else if (e.key === ".") {
      var dotCount = (e.target.value.match(/\./g) || []).length;
      if (dotCount >= 2) {
        e.preventDefault();
        return false;
      }
    } else if (e.key === "-") {
      var dashCount = (e.target.value.match(/-/g) || []).length;
      if (dashCount >= 2) {
        e.preventDefault();
        return false;
      }
    } else if (e.key === "," && e.target.value.indexOf(",") !== -1) {
      e.preventDefault();
      return false;
    }
  };

  const getCoordinate = (value) => {
    var parts = value.split(",");
    if (parts.length !== 2) {
      return null;
    }
    var p1 = parts[0].trim();
    var p2 = parts[1].trim();
    var ret1 = Number(p1);
    var ret2 = Number(p2);
    if (
      isNaN(ret1) ||
      ret1 < -90 ||
      ret1 > 90 ||
      isNaN(ret2) ||
      ret2 < -180 ||
      ret2 > 180
    ) {
      return null;
    }
    return [ret1, ret2];
  };

  const handleResultClick = (result) => {
    const value = result.poi
      ? `${result.poi.name} (${result.address.freeformAddress})`
      : result.address.freeformAddress;
    const position = result.position.lat + ", " + result.position.lon;
    setSearchBox(value);
    setSearchBox2(position);

    window.map.markers.clear();
    //Show the suggestion as a pushpin and center map over it.
    var center = new atlas.data.Position(
      result.position.lon,
      result.position.lat
    );
    const pin = new atlas.HtmlMarker({
      position: center,
      color: "#005aa1",
    });
    window.map.markers.add(pin);

    // Add a separate text element below the pin
    const textElement = document.createElement("div");
    textElement.innerText = value;
    textElement.style.position = "absolute";
    textElement.style.transform = "translate(-50%, 10px)";
    textElement.style.textAlign = "center";
    textElement.style.width = "300px";
    textElement.style.pointerEvents = "none";
    pin.getElement().appendChild(textElement);

    window.map.setCamera({ center: center, zoom: 16 }); // Set zoom level to 16

    setLoc({
      address: result.address,
      lat: result.position.lat,
      long: result.position.lon,
    });

    setSearchResults([]); // Clear search results after selection
  };

  const clearSearchResults = () => {
    setSearchResults([]);
    setSearchBox("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => {
        closeModal();
      }}
      isBlocking={false}
    >
      <Wrap>
        <SearchBoxContainer id="searchBoxContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Icon iconName="Search" />
            <SearchBox
              value={searchBox}
              onChange={(e) => setSearchBox(e.target.value)}
              type="text"
              id="searchBox"
              placeholder={t("rvm.locationForm.mapSearchLocations")}
              autoComplete="off"
            />
            {searchBox && (
              <span
                onClick={clearSearchResults}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  fontSize: "16px",
                  color: "#ccc",
                }}
              >
                &#x2715;
              </span>
            )}
          </div>
          {isSearchListReady && searchResults.length > 1 && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "white",
                border: "1px solid #ccc",
                width: "100%",
                zIndex: 1000,
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  onClick={() => handleResultClick(result)}
                >
                  {result.poi
                    ? `${result.poi.name} (${result.address.freeformAddress})`
                    : result.address.freeformAddress}
                </div>
              ))}
            </div>
          )}
        </SearchBoxContainer>
      </Wrap>
      <HSpace />
      <div
        style={{
          position: "absolute",
          top: "18px",
          zIndex: "99",
          background: "white",
          width: "400px",
          left: "330px",
        }}
      >
        <SearchBoxContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon iconName="GlobeFavorite" />
            <SearchBox
              value={searchBox2}
              onChange={(e) => setSearchBox2(e.target.value)}
              type="text"
              id="searchBox2"
              onKeyPress={onKeyPress}
              onFocus={(e) => {
                setSearchResults([]);
              }}
              placeholder={t("rvm.locationForm.mapSearchGeoCoordinate")}
            />
          </div>
        </SearchBoxContainer>
      </div>
      <div
        id="azure-map"
        style={{
          position: "relative",
          width: "900px",
          height: "570px",
        }}
      />
      <BtnsWrap>
        <PrimaryButton
          text={t("rvm.locationForm.mapSave")}
          styles={{
            root: {
              marginRight: "5px",
            },
          }}
          onClick={() => {
            let countryCode;
            if (loc.address?.countryRegionISO2) {
              const tax =
                taxonomy.Country.byCode[
                  `Country.${loc.address?.countryRegionISO2}`
                ];
              if (tax) {
                countryCode = {
                  label: t(`Country.${loc.address?.countryRegionISO2}`),
                  value: tax.id,
                };
              }
            }
            if (loc.address?.countryCode) {
              let countryRegionCode;
              for (const [key, countryName] of Object.entries(countries)) {
                if (loc.address?.countryCode === key) {
                  countryRegionCode = key;
                }
              }
              if (countryRegionCode !== undefined) {
                const tax =
                  taxonomy.Country.byCode[`Country.${countryRegionCode}`];
                if (tax) {
                  countryCode = {
                    label: t(tax.code),
                    value: tax.id,
                  };
                }
              }
            }

            setFieldValue("locationName", loc.address?.freeformAddress);
            setFieldValue("street", loc.address?.freeformAddress);
            setFieldValue("city", loc.address?.localName);
            setFieldValue("zipCode", loc.address?.postalCode ?? "");
            setFieldValue("city", loc.address?.locality);
            setFieldValue("latitude", loc.lat);
            setFieldValue("longitude", loc.long);
            setFieldValue("formattedAddress", loc.address?.freeformAddress);
            setFieldValue("countryCodeID", countryCode ?? null);
            setFieldValue("street", loc.address?.streetName);
            setFieldValue("house", loc.address?.streetNumber);
            setFieldValue("buildingFloorDoor", "");
            closeModal();
          }}
          disabled={loc && !loc.address}
        />
        <DefaultButton
          text={t("rvm.locationForm.mapClose")}
          onClick={closeModal}
        />
      </BtnsWrap>
      <GlobalStyle />
    </Modal>
  );
};

const SearchBoxContainer = styled.div`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 5px;
  border: 0;
  outline: none;
`;
const Wrap = styled.div`
  position: absolute;
  top: 18px;
  z-index: 99;
  background: white;
  width: 300px;
  left: 20px;
`;
const BtnsWrap = styled.div`
  padding: 5px;
  margin: 5px 0px;
  text-align: right;
  position: absolute;
  right: 5px;
  bottom: 0;
  background: transparent;
  z-index: 101;
`;

// Add CSS for normal cursor
const GlobalStyle = createGlobalStyle`
  .atlas-map-canvas-container {
    cursor: default !important;
    -webkit-cursor: default !important;
  }
`;
